import {extend, override} from 'flarum/extend';
import DiscussionPage from 'flarum/components/DiscussionPage';
import IndexPage from 'flarum/components/IndexPage';


const getCarbonAd = () => {
    const serve = app.data['bsa_carbon_serve'];
    const placement = app.data['bsa_ad_placement'];

    return <script async type="text/javascript" src={`//cdn.carbonads.com/carbon.js?serve=${serve}&placement=${placement}`} id="_carbonads_js"></script>
};


app.initializers.add('buysellads', () => {
    if(typeof _bsa !== 'undefined' && _bsa) {
        let native = app.data['bsa_native_js'];
        let placement = app.data['bsa_ad_placement'];
        _bsa.init('flexbar', native, 'placement:' + placement);
    }

    extend(DiscussionPage.prototype, 'config', function (isInitialized, context) {
      const header = $('.App-header');
      const ul = $('body .DiscussionPage-nav ul');
      this.hit = 0;
      if (ul.length > 0) {
        this.hit = ul.offset().top - 30 - header.height();
      }

      this.handler = null;

      $(window).on('scroll', this.handler = (e) => {
        const scroll = document.documentElement.scrollTop
        if (scroll >= this.hit) {
          return $('body .DiscussionPage-nav ul').addClass('fixed')
        }

        return $('body .DiscussionPage-nav ul').removeClass('fixed')
      });
    });

    extend(DiscussionPage.prototype, 'onunload', function() {
      $(window).off('scroll', this.handler);
    });

    // carbon ads
    extend(DiscussionPage.prototype, 'sidebarItems', items => items.add('ads', getCarbonAd(), 100));
    extend(IndexPage.prototype, 'sidebarItems', items => items.add('ads', getCarbonAd(), -100));

    // remove welcome hero
    override(IndexPage.prototype, 'hero', orig => {
        return null;
    });
});
